const request = require('superagent');

class DomainBlacklistService {

  static get(id) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}domain_blacklists/${id}`);
  }
}

export default DomainBlacklistService;
