import React from 'react';
import { NavLink } from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import {CreatedInDayAgo} from "../../helpers/time"
import 'react-toastify/dist/ReactToastify.css';


import TestService from '../../services/test.service';
//import ConversationService from '../../../../services/conversation.service';
//import TemplateService from '../../../../services/template.service';
import AccountService from '../../services/account.service';
import DomainBlacklistService from '../../services/domainBlacklist.service';

import WordsAnalyzer from '../../components/words-analyzer/words-analyzer.js';
import CheckupDomain from '../../components/checkup-domain/checkup-domain.js';


import './test.scss';

let adsRd = [2,2,2,2]
adsRd = adsRd[Math.floor(Math.random() * adsRd.length)];


class AppSpfTests extends React.Component {
	constructor(props) {
		super(props);
    this.state = {errors: [], spamLogs: [], template: {}, openShowHeadersModal: false, headersToShow: [], blockedNextTest: false, loading: true, showPopup: false, currentPage: 'test', errors: {setup: 0}, currentTest:{account: {}}, currentAccount: {}, test_type: 'all', messageContentPreview: 'content_html'};
		this.reloadTests = this.reloadTests.bind(this);
		this.loadPopup = this.loadPopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.openHeadersModal = this.openHeadersModal.bind(this);
		this.openShowRecordModal = this.openShowRecordModal.bind(this);
	}

	componentWillMount() {
    this.setState({testId: this.props.match.params.test_id});
    if(this.props.match.params.test_id){
			TestService.get(this.props.match.params.test_id).then((response) => {

				let currentTest = response.body;

				currentTest.results = currentTest.results.sort(function(a, b){
					if(a.provider < b.provider) { return -1; }
					if(a.provider > b.provider) { return 1; }
					return 0;
				});

				this.setState({currentTest: currentTest, loading: false});



				setTimeout(() => {
				  this.loadPopup();
				}, 3000);


				if(!response.body.completed){
					// Reload test every 8s if not completed
					setInterval(
						() => this.reloadTests(),
						2000
					);
				}
				this.checkBlockedNextTest();
			})
			.catch((error) => {
				console.error(error);
				//window.location.href = "https://www.mailreach.co/upgrade/";
			});
    }
	}

	reloadTests(){
    this.setState({reloadTests: true});
    TestService.get(this.props.match.params.test_id).then((response) => {
			// Redirect to upgrade page if test is blocked
			if(response.body.blocked){
				window.location.href = "https://www.mailreach.co/upgrade/";
				return;
			}
      this.setState({currentTest: response.body, reloadTests: false})
			this.checkBlockedNextTest();
			this.loadPopup();
    })
    .catch((error) => {
			console.log(error);
      toast.error('Oops, we have an issue, try again');
    });
	}

	checkBlockedNextTest(){
		TestService.checkBlocked(this.props.match.params.test_id).then((response) => {
			this.setState({blockedNextTest: response.body.blocked})
		})
	}

	getScoreClass(score){
		if(score < 5){
			return 'bad';
		} else if (score < 7.5) {
			return 'average'
		}else {
			return 'good'
		}
	}

	getInboxTypelabel(email){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let domain = email.split('@')[1];
		if(freeMails.includes(domain)){
			return '<span class="inbox-type-label perso">Personal</span>'
		}else{
			return '<span class="inbox-type-label pro">Professional</span>'
		}
	}

	getInboxType(email){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let domain = email.split('@')[1];
		if(freeMails.includes(domain)){
			return 'perso'
		}else{
			return 'pro'
		}
	}

	copyText(value){
		navigator.clipboard.writeText(value);
		toast.success('Share link successfully copied 🙌');
	}

	loadPopup(){
		if(!localStorage.getItem('hide_popup_wrm')){
			if(this.state.currentTest.btob_score < 8){
				if(this.state.currentTest.domain){
					if(this.state.currentTest.domain.spf_valid && this.state.currentTest.domain.dkim_valid && ['gmail.com', 'google.com', 'outlook.com', 'outlook.fr', 'hotmail.com'].includes(this.state.currentTest.sending_provider)){
						if(this.state.showPopup == false){
							this.setState({showPopup: true});
							window.gtag("event", "spamtest", {
											event_category: "spamtest",
											event_label: "warmer_pop_up"
									});
						}
					}
				}
			}
		}
	}

	closePopup(){
		this.setState({showPopup: false});
		localStorage.setItem('hide_popup_wrm', true);
	}

	openHeadersModal(log){
		if(Array.isArray(log.raw_headers)){
			this.setState({openShowHeadersModal: true, headersToShow: log.raw_headers});
		}
	}

	openShowRecordModal(record){
		this.setState({openShowRecordModal: true, modalRecordValue: record});
	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
					<ToastContainer />
				</div>
			)
		}

		if(!this.state.currentTest.completed){
			return(
				<div className="app container home">
					<div className="card upgrade-spamchecker waiting">
						<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/home${this.props.location.search}`} className="close" activeClassName="active">
							<i class="fas fa-xmark"></i>
						</NavLink>
						<div className="col-md-9">
							<h2>MailReach is waiting to receive your email</h2>
							<p>
							It usually takes between 20 and 60 seconds to load your result. If nothing shows up after 60 seconds :<br /><br />
							1. Are you sure you added the custom code in your email?<br />
							2. Are you sure your email has been sent?<br />
							</p>
						</div>

						<div className="col-md-3 text-center">
							<div className="loader">
								<Loader
								 type="Oval"
								 color="#b7b7b7"
								 height="100"
								 width="100"
								/>
							</div>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			)
		}

		return (
      <>

			<div className="app home container spamtest-results spam-results-modal template-home spf-test">


				<div className="row accounts templates col-md-12 container">

        <h2>SPF Check</h2>
        <div className="pull-right hide-responsive">
          <button type="submit" disabled={this.state.loading} className="btn btn-header btn-secondary pull-right" onClick={() => this.setState({openShowTemplateModal: true})}>See my email</button>
        </div>

       <div className="clearfix"></div>

        <div className={'txt text-center'}>
        {this.state.currentPage == 'test' &&
          <>
          {this.state.currentTest.completed &&
            <>
              <div className="col-md-3 no-padding-left text-left">
                <div className="card">
                  <div className="title">
                  	SPF status
                  </div>
                  <div className="text-center">
                    <Tooltip title="This score is 100% based on where your emails land as it's the best representation of your test email's deliverability. The formula is : [number of emails landed in inbox] / [number of email sent] x 10." placement="bottom">
                      <div>
                        {(this.state.currentTest.domain.spf_value && this.state.currentTest.domain.spf_valid) &&
                          <div className={'inbox-rate-nb score good'}>
                            Valid
                          </div>
                        }

                        {(this.state.currentTest.domain.spf_value && !this.state.currentTest.domain.spf_valid) &&
                          <div className={'inbox-rate-nb score bad'}>
                            Invalid
                          </div>
                        }

                        {(!this.state.currentTest.domain.spf_value && !this.state.currentTest.domain.spf_valid) &&
                          <div className={'inbox-rate-nb score bad'}>
                            Missing
                          </div>
                        }
                      </div>

                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="col-md-9 no-padding-right text-left">
                <div className="card record-info">
                  {(this.state.currentTest.domain.spf_value && this.state.currentTest.domain.spf_valid) &&
                    <div className="insight-content valid-record">
                      <h2 className="pull-left">Here's the SPF record of {this.state.currentTest.domain.domain}</h2>
                      <p className="pull-left record-value cursor-pointer" onClick={() => this.openShowRecordModal(this.state.currentTest.domain.spf_value)}>{this.state.currentTest.domain.spf_value.slice(0, 80)} {this.state.currentTest.domain.spf_value.length > 80 ? '..' : ''}</p>
                      <p className="pull-left txt-desc-spf">Your SPF record matches the way your email has been sent. Well done 👌</p>
                      <div className="clearfix"></div>
                    </div>
                  }

                  {(!this.state.currentTest.domain.spf_value) &&
                    <div className="insight-content little-txt missing-spf-record">
                      <h2 className="pull-left">MailReach didn’t find any SPF record</h2>
											<p>MailReach didn't find any SPF record for {this.state.currentTest.domain.domain}. Read our guide below to properly set up your SPF 👇</p>
                      <div className="col-md-12 no-padding-left">
                        <a className="btn btn-primary green-bck invalid-btn missing-btn" href='https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/#1-how-to-set-up-your-spf-record' target="_blank">
                        How to setup your SPF record
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  }

                  {(this.state.currentTest.domain.spf_value && !this.state.currentTest.domain.spf_valid) &&
                    <div className="insight-content little-txt invalid_record">
                      <h2 className="pull-left">The record is invalid for the setup used to send your email</h2>

											<p className="pull-left record-value cursor-pointer" onClick={() => this.openShowRecordModal(this.state.currentTest.domain.spf_value)}>
												&nbsp; {this.state.currentTest.domain.spf_value}
												<span className="pull-left">
													<Tooltip title={"Here's the SPF record of " + this.state.currentTest.domain.domain} placement="bottom">
														<div>
															<i class="fas fa-info-circle"></i>
														</div>
													</Tooltip>
												</span>
											</p>
                      <div className="col-md-12">
                        <a className="btn btn-primary green-bck invalid-btn" href='https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/#1-how-to-fix-the-error-invalid-spf-record-found' target="_blank">
                        How to setup SPF & DKIM
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  }
                </div>
              </div>
            </>
          }

					<div className="col-md-12 no-padding-right no-padding-left text-left">
						<div className="card upgrade-spamchecker">
						<div className="insight-content">
							<h2 className="pull-left">Sending cold emails? Improve your open rates and revenue now 🔥</h2>
							<p className="pull-left">MailReach's email warmer generates engagement to your emails to raise your sender reputation and deliverability. It teaches Gmail & Outlook to send your emails to the inbox. Get better results from your emails and start making more revenue now.</p>
							<div className="col-md-12">
								<a className="btn btn-primary green-bck" href={'https://www.mailreach.co/email-warmup' + (this.props.location.search ? ( this.props.location.search + 'utm_source=spf_checker&utm_medium=warmer_cta&utm_campaign=spf-result') : '?utm_source=spf_checker&utm_medium=warmer_cta&utm_campaign=spf-result')} target="_blank">
									Boost my deliverability
								</a>
							</div>
							<div className="clearfix"></div>
						</div>

						</div>
					</div>

          <div className="clearfix"></div>
          <h2 className="second-title">
            Bonus checks
          </h2>
          {this.state.reloadTests &&
            <div className="pull-left spamtests-loading top-margin">
              <Loader
               type="Oval"
               color="#b7b7b7"
               height="25"
               width="25"
              />
            </div>
          }
          <div className="share-link hide-responsive" onClick={() => this.copyText(window.location.href.split('?')[0])}>
            <div className="code">
              {window.location.href.split('?')[0]}
            </div>
            <div className="copy">
              <i className="far fa-copy" aria-hidden="true"></i>
            </div>
          </div>
          <div className="share-txt hide-responsive">
            Share this report
          </div>
          <div className="clearfix"></div>
          </>
        }
							<div className="checkups">

								<CheckupDomain domain={this.state.currentTest.domain} currentTest={this.state.currentTest} spamTest={true} spf={true} />
								<div className="clearfix"></div>
							</div>

          </div>
        </div>
      </div>
      {this.state.currentTest.first_message_content &&
  			<Modal className="spam-results-modal message-design" show={this.state.openShowTemplateModal} onHide={() => this.setState({openShowTemplateModal: false})}>
  				<Modal.Body>
  					<>
  						<h1 className="text-center">{this.state.currentTest.first_message_content.subject}</h1>
  						<div className='from'>
  							<b>{this.state.currentTest.first_message_content.from_name}</b> {'<' + this.state.currentTest.first_message_content.from_email + '>'}
								<ul className='format-selector pull-right'>
									<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_html' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_html'})}>HTML</li>
									<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_text' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_text'})}>TEXT</li>
								</ul>
  						</div>
  						<div className="txt">
  						<div
  dangerouslySetInnerHTML={{__html: this.state.currentTest.first_message_content[this.state.messageContentPreview]}}
  />
  						</div>
  						<div className="footer text-center">
  							<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowTemplateModal: false})}>Close</button>
  						</div>
  					</>
  				</Modal.Body>
  			</Modal>
      }

				<Modal className="spam-results-modal message-design" show={this.state.openShowHeadersModal} onHide={() => this.setState({openShowHeadersModal: false})}>
					<Modal.Body>
						<>
							<h1 className="text-center">Message Raw Headers</h1>
							<div className='headers'>
								<table>
									<tbody>
										{this.state.headersToShow.map((header, index) => {
											return (
												<tr>
													<td>{header.name} :</td>
													<td className="break-word">{header.unparsed_value}</td>
												</tr>
											)})}

									</tbody>
								</table>
							</div>
							<div className="footer text-center">
								<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowHeadersModal: false})}>Close</button>
							</div>
						</>
					</Modal.Body>
				</Modal>


				<Modal className="reputation-modal" show={this.state.openShowRecordModal} onHide={() => this.setState({openShowRecordModal: false})}>
					<Modal.Body>
						<>
							<h1 className="text-center">SPF record</h1>
							<div className='headers'>
								<p className="line-break text-center">{this.state.modalRecordValue}</p><br />
							</div>
							<div className="footer text-center">
								<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowRecordModal: false})}>Close</button>
							</div>
						</>
					</Modal.Body>
				</Modal>

			<ToastContainer />
			<footer className="text-center">
				<a href="https://www.mailreach.co/?utm_source=free_spamchecker&utm_medium=footer-links&utm_campaign=mailreach-cta" target="_blank">Maximize your cold email deliverability with MailReach</a>
				<a href="https://www.mailreach.co/blog/why-are-my-emails-going-to-spam?utm_source=free_spamchecker&utm_medium=footer-links&utm_campaign=why-emails-going-to-spam" target="_blank">Why are my emails going to spam?</a>
				<a href="https://www.mailreach.co/blog/how-to-prevent-emails-from-going-to-spam?utm_source=free_spamchecker&utm_medium=footer-links&utm_campaign=deliverability-guide" target="_blank">The Ultimate Deliverability Guide</a>
			</footer>

      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default AppSpfTests;
