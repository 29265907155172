const request = require('superagent');

class InappMessageService {
  static get(key) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}inapp_messages/${key}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default InappMessageService ;
