const request = require('superagent');

class AccountService {
  static all() {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}accounts`);
  }
}

export default AccountService ;
