import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, useLocation} from 'react-router-dom';

import './App.css';

import Sidebar from './components/sidebar/sidebar.js';

import AppTests from './pages/tests/test.js';
import AppSpfTests from './pages/tests/spf-test.js';
import AppDkimTests from './pages/tests/dkim-test.js';
import AppSummary from './pages/summary/summary.js';
import AppRedirect from './pages/redirect/redirect.js';

function App() {
  return (
    <div className="app">
      <Sidebar />
      <div className="app side">
        <div className="app home spamtest-results spam-results-modal template-home">
          <Suspense fallback="loading">
            <Router>
                <Route path="/rd" component={AppRedirect}/>
                <Route path="/tests/:test_id" component={AppTests}/>
                <Route path="/spf/:test_id" component={AppSpfTests}/>
                <Route path="/dkim/:test_id" component={AppDkimTests}/>
                <Route path="/summary/:email" component={AppSummary}/>
                <Route exact path="/">
                  <Redirect to="/rd" />
                </Route>
            </Router>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default App;
