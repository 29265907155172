import React from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import queryString from 'query-string';


class AppRedirect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], projects: [], error: false, loading: true};
	}


	componentWillMount() {
		if(window.location.host == 'spfchecker.mailreach.co'){
			window.location.href = "https://www.mailreach.co/email-spf-checker/";
		}else if (window.location.host == 'dkimchecker.mailreach.co') {
			window.location.href = "https://www.mailreach.co/dkim-checker/";
		}else{
			window.location.href = "https://www.mailreach.co/email-spam-test/";
		}
	}

	componentWillReceiveProps(nextProps) {

	}



	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		return (
      <div>
        Redirect
      </div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		//organization: state.organizations
	};
}

function mapDispatchToProps(dispatch) {
	return {
		//actions: bindActionCreators(organization, dispatch)
	};
}

export default AppRedirect;
