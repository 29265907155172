import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import { Dropdown } from 'react-bootstrap';

import './sidebar.scss';

class Sidebar extends React.Component {

	constructor(props) {
		super(props);
	}

	componentWillMount() {
	}

	componentWillReceiveProps(nextProps) {
	}



	render() {
		return (
			<>
				<div className="sidebar">
					<div className="ct">
						<div className="logo">
							<div className="bck" >
								<a href="">
									<img src="/images/logo.png" />
								</a>
							</div>
						</div>
					</div>
          </div>
				</>
				)
	}
}


export default Sidebar;
