const request = require('superagent');

class TestService {
  static get(testId) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}tests/${testId}`);
  }

  static setType(testId, type) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}tests/${testId}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
            .send({type});
  }

  static checkBlocked(testId) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}tests/${testId}/blocked`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default TestService ;
