import React from 'react';
import {withRouter} from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';

import {CreatedInDayAgo} from "../../helpers/time"

import DomainBlacklistService from '../../services/domainBlacklist.service';

import InappMessage from '../inapp-message/inapp-message.js';


class CheckupDomain extends React.Component {

	constructor(props) {
		super(props);
		this.state = {error: false, domain: {}, blacklists: [], nbIssue: 0, blacklistsModal: []};
    this.openRecordValue = this.openRecordValue.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.loadAccount = this.loadAccount.bind(this);
    this.openBlacklist = this.openBlacklist.bind(this);
    this.openInappMessage = this.openInappMessage.bind(this);
	}

  componentWillMount() {
    this.loadAccount();
  }

  loadAccount(){
    DomainBlacklistService.get(this.props.currentTest.public_id).then((response) => {
      this.setState({blacklists: response.body})
    })
  }

  handleCloseModal() {
    this.setState({showRecordModal: false, showBlacklistModal: false, openModalKey: null});
  }

  openInappMessage(key){
    this.setState({openModalKey: key})
  }

  openBlacklist(){
    this.setState({showBlacklistModal: true})
  }

  openRecordValue(recordName, recordValue){
    this.setState({showRecordModal: true,
                   modalRecordName: recordName,
                   modalRecordValue: recordValue});
  }


	render() {
		return (
      <>
      <div>

			{this.props.spf &&
				<>
					{(this.props.currentTest.spam == true) &&
						<div className="card col-md-12">
							<p className="delete pull-left">
								<i class="far fa-times-circle"></i>
								Your email landed in the spam folder. Run a spam test to check your deliverability
							</p>
							<a href="https://www.mailreach.co/email-spam-test/?utm_source=free_spfchecker&utm_medium=mini_spamcheck" target="_blank" className="btn btn-secondary pull-right" >Run a spam test</a>
							<div className="clearfix"></div>
						</div>
					}
				</>
			}


      {this.props.domain.creation_date &&
        <div className="card col-md-12">
          <p className="delete pull-left">
            {CreatedInDayAgo(this.props.domain.creation_date) > 31 &&
              <>
                <Tooltip title='👌 Good domain age' placement="left">
                  <div className="pull-left">
                    <i class="far fa-check-circle"></i>
                  </div>
                </Tooltip>

                Domain created <Moment fromNow>{this.props.domain.creation_date}</Moment>
              </>
            }
            {CreatedInDayAgo(this.props.domain.creation_date) < 32 &&
              <>
              <Tooltip title='👶 Young domain' placement="left">
                <div className="pull-left">
                  <i class="far fa-times-circle"></i>
                </div>
              </Tooltip>
              Your domain is very young and can be considered suspicious. Behave well 🤓
              </>
            }
          </p>
					<div className="clearfix"></div>
        </div>
      }

			{this.props.currentTest.blacklist_sending_ip.length == 0 &&
				<div className="card col-md-12">
					<p className="delete pull-left">
						<i class="far fa-check-circle"></i>
						Your email server's IP is not listed on significant blacklists.
					</p>
					<div className="clearfix"></div>
				</div>
			}

			{this.props.currentTest.blacklist_sending_ip.length > 0 &&
				<div className="card col-md-12">
					<p className="delete pull-left">
						<i class="far fa-dot-circle"></i>
						Your email server's IP is listed on {this.props.currentTest.blacklist_sending_ip.length} blacklist(s).
					</p>
					<button type="submit" className="btn btn-secondary pull-right" onClick={() => this.setState({showBlacklistModal: true, blacklistsModal: this.props.currentTest.blacklist_sending_ip})}>Details</button>
					<div className="clearfix"></div>
				</div>
			}


      <div className="card col-md-12">
        {this.state.blacklists.filter(b => b.listed).length === 0 &&
          <p className="delete pull-left">
            <i class="far fa-check-circle"></i> Your domain isn't listed in any blacklist. Well done.
          </p>
        }
        {this.state.blacklists.filter(b => b.listed).length > 0 &&
          <>
            <p className="delete pull-left">
              <i class="far fa-dot-circle"></i> Your domain is in {this.state.blacklists.filter(b => b.listed).length} blacklist(s)
            </p>
          </>
        }
				<button className="btn btn-secondary pull-right hide-responsive" onClick={() => this.setState({showBlacklistModal: true, blacklistsModal: this.state.blacklists})}>See blacklists</button>
				<div className="clearfix"></div>
      </div>

	      <div className="card col-md-12">
					{(this.props.domain.spf_valid) &&
						<>
							<p className="delete pull-left">
								<Tooltip title='SPF well setup 👌' placement="left">
									<div className="pull-left">
										<i class="far fa-check-circle"></i> SPF check - we found a valid record
									</div>
								</Tooltip>
							</p>
							{this.props.domain.spf_value &&
								<button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openRecordValue('SPF', this.props.domain.spf_value)}>Details</button>
							}
						</>
					}
					{(this.props.domain.spf_value && !this.props.domain.spf_valid) &&
						<>
							<p className="delete pull-left">
								<Tooltip title='SPF not found ⚠️' placement="left">
									<div className="pull-left">
										<i class="far fa-times-circle"></i> SPF check - we found a record but it's not valid
									</div>
								</Tooltip>
							</p>
							<button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openRecordValue('SPF_INVALID', this.props.domain.spf_value)}>Details</button>
						</>
					}
					{(!this.props.domain.spf_value && !this.props.domain.spf_valid) &&
						<>
							<p className="delete pull-left">
								<Tooltip title='Check our recommendations ⚠️' placement="left">
									<div className="pull-left">
										<i class="far fa-times-circle"></i> SPF check - No record found
									</div>
								</Tooltip>
							</p>
							<a className="btn btn-secondary pull-right hide-responsive" href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">Details</a>
						</>
					}
					<div className="clearfix"></div>
	      </div>


	      <div className="card col-md-12">
	        <p className="delete pull-left">
						{(this.props.domain.dkim_valid) &&
							<>
								<i class="far fa-check-circle"></i> DKIM check - We found a record on selector &nbsp;
								{this.props.domain.dkim_selector &&
									<>
										{this.props.domain.dkim_selector.length > 25 &&
											<>
												{this.props.domain.dkim_selector.substring(0, 25)} ...
											</>
										}
										{this.props.domain.dkim_selector.length <= 25 &&
											<>
												{this.props.domain.dkim_selector}
											</>
										}
									</>
								}
							</>
						}
						{(this.props.domain.dkim_value && !this.props.domain.dkim_valid) &&
							<>
								<i class="far fa-times-circle"></i> DKIM check - we found a record but it's not valid
								{this.props.domain.dkim_selector &&
									<>
										{this.props.domain.dkim_selector.length > 25 &&
											<>
												{this.props.domain.dkim_selector.substring(0, 25)} ...
											</>
										}
										{this.props.domain.dkim_selector.length <= 25 &&
											<>
												{this.props.domain.dkim_selector}
											</>
										}
									</>
								}
							</>
						}
						{(!this.props.domain.dkim_value && !this.props.domain.dkim_valid) &&
							<>
								<i class="far fa-times-circle"></i> DKIM check - No record found
							</>
						}


	        </p>
	        {(this.props.domain.dkim_valid) &&
						<>
							{this.props.domain.dkim_value &&
	          		<button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openRecordValue('DKIM', this.props.currentTest.domain.dkim_value)}>Details</button>
							}
						</>
	        }
					{(this.props.domain.dkim_value && !this.props.domain.dkim_valid) &&
						<button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openRecordValue('DKIM_INVALID', this.props.currentTest.domain.dkim_value)}>Details</button>
					}
	        {(!this.props.domain.dkim_value && !this.props.domain.dkim_valid) &&
	          <a className="btn btn-secondary pull-right hide-responsive" href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">Details</a>
	        }
					<div className="clearfix"></div>
	      </div>

	      <div className="card col-md-12">
	        <p className="delete pull-left">
	          {(this.props.domain.dmarc_value ||  this.props.domain.dmarc_valid) &&
	            <>
	              <i class="far fa-check-circle"></i> DMARC check - We found a record
	            </>
	          }
	          {(!this.props.domain.dmarc_value && !this.props.domain.dmarc_valid) &&
	            <>
	              <i class="far fa-dot-circle"></i> DMARC check - No record found.
	            </>
	          }

	        </p>
	        {this.props.domain.dmarc_value &&
	          <button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openRecordValue('DMARC', this.props.currentTest.domain.dmarc_value)}>Details</button>
	        }
	        {!this.props.domain.dmarc_value &&
	          <button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openInappMessage('accounts_checkup_dmarc_setup_tuto')}>Details</button>
	        }
					<div className="clearfix"></div>
	      </div>

				<div className="card col-md-12">
					<p className="delete pull-left">
						{this.props.currentTest.sending_reverse_dns_value &&
							<>
								<i class="far fa-check-circle"></i> Your mail server Reverse DNS is properly configured.
							</>
						}
						{!this.props.currentTest.sending_reverse_dns_value &&
							<>
								<i class="far fa-dot-circle"></i> Your mail server Reverse DNS is not configured.
							</>
						}

					</p>
					{this.props.currentTest.sending_reverse_dns_value &&
						<button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openRecordValue('REVERSE_DNS', this.props.currentTest.sending_reverse_dns_value)}>Details</button>
					}
					<div className="clearfix"></div>
				</div>

				{this.props.domain.mx_records &&
					<div className="card col-md-12">
						<p className="delete pull-left">
							{this.props.domain.mx_records.length > 0 &&
								<>
									<i class="far fa-check-circle"></i> There are MX records on this domain
								</>
							}
							{this.props.domain.mx_records.length == 0 &&
								<>
									<i class="far fa-dot-circle"></i> There are no MX records on this domain
								</>
							}

						</p>
						<div className="clearfix"></div>
					</div>
				}

      {/*
        <div className="card col-md-12">
          {this.state.currentAccount.domain.domain_http_response &&
            <p className="delete pull-left">
              <i class="far fa-check-circle"></i> Website setup don't use secure connection (HTTPS)
            </p>
          }
          {!this.state.currentAccount.domain.domain_http_response &&
            <>
              <p className="delete pull-left">
                <i class="far fa-times-circle"></i> No website or redirection has been found behind {this.state.currentAccount.domain.domain} or www.{this.state.currentAccount.domain.domain}.
              </p>
              <button className="btn btn-secondary pull-right" onClick={() => this.openInappMessage('accounts_checkup_website_reco')}>Recommendations</button>
            </>
          }
        </div>
      */}

    </div>

		<InappMessage keyname={this.state.openModalKey} show={this.state.openModalKey} handleCloseModal={this.handleCloseModal} />

    <Modal className="reputation-modal" show={this.state.showRecordModal} onHide={this.handleCloseModal}>
      <Modal.Body>
          <>
							{this.state.modalRecordName == 'SPF_INVALID' &&
								<h1 className="text-center">
									Invalid SPF record found
								</h1>
							}
							{this.state.modalRecordName == 'DKIM_INVALID' &&
								<h1 className="text-center">
									DKIM record found but it's not valid or incomplete.
								</h1>
							}

						{this.state.modalRecordName == 'SPF_INVALID' &&
							<div className="txt text-center">
								{!this.state.modalRecordValue &&
									<>
										<p>A valid SPF record has to cover all the email providers used to send emails from your domain.</p>

										<p>There’s an SPF record set up for your domain BUT it doesn’t cover how your emails are sent OR there’s an error in the record.</p>

										<p>For example, if you send emails from your domain using Gmail, you need to properly set up your SPF for Gmail.</p>

										<p>Second example, if you send emails from your domain using Brevo and Outlook, your SPF record has to cover both Brevo and Outlook.</p>

										<p>Second example, if you send emails from your domain using Brevo and Outlook, your SPF record has to cover both Brevo and Outlook.</p>

										<p>Read our guide to fix your SPF 👉 <a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">How to properly setup SPF and DKIM record</a></p>
									</>
								}

								{this.state.modalRecordValue &&
									<div className="text-left">
										<p><b>Your current SPF record</b></p>

										<p className="line-break">{this.state.modalRecordValue}</p>

										<p><b>Guidelines</b></p>

										<p>Your SPF record can be detected as invalid for 3 reasons:</p>

										<ul className="no-list-style">
											<li>1. There’s an error in the record</li>
											<li>2. You have multiple SPF records. You should only have one.</li>
											<li>3. The record doesn’t include the email service provider(s) used to send emails from your domain</li>
										</ul>

										<p>A valid SPF record has to cover all the email providers used to send emails from your domain.</p>

										<p>For example, if you send emails from your domain using Gmail, you need to set up your SPF for Gmail.</p>

										<p>Second example, if you send emails from your domain using Brevo and Outlook, your SPF record has to include both Brevo and Outlook.</p>

										<p>Read our guide to fix your SPF 👉 <a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">How to properly setup SPF and DKIM record</a></p>
									</div>
								}
							</div>
						}

						{this.state.modalRecordName == 'DKIM_INVALID' &&
							<div className="txt text-center">
								{!this.state.modalRecordValue &&
									<>
										<p>There’s a DKIM record set up for your domain BUT it doesn’t cover how your emails are sent OR there’s an error in the record.</p>

										<p>For example, if you send emails from your domain using Gmail, you need to properly set up your DKIM for Gmail.</p>

										<p>Second example, if you send emails from your domain using Brevo and Outlook, you will need two separate DKIM records, one for Brevo and one for Outlook.</p>

										<p>Read our guide to fix your DKIM 👉 <a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">How to properly setup SPF and DKIM record</a></p>
									</>
								}


								{this.state.modalRecordValue &&
									<div className="text-left">
										<p><b>Your current DKIM record</b></p>

										<p className="line-break">{this.state.modalRecordValue}</p>

										<p><b>Guidelines</b></p>

										<p>Your DKIM record can be detected as invalid for 3 reasons:</p>

										<ul className="no-list-style">
											<li>1. The record doesn’t match how your emails are sent on MailReach</li>
											<li>2. The record doesn’t include the right selector</li>
											<li>3. There’s an error in the record</li>
											</ul>

										<p>if you send emails from your domain using Gmail, you need to properly set up your DKIM for Gmail.</p>

										<p>If you send emails from your domain using Brevo and Outlook, you need two separate DKIM records, one for Brevo and one for Outlook.</p>

										<p>Read our guide to fix your DKIM 👉 <a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">How to properly setup SPF and DKIM record</a></p>
									</div>
								}
							</div>
						}

						{!['SPF_INVALID', 'DKIM_INVALID'].includes(this.state.modalRecordName) &&
							<>
									<h1 className="text-center">Your {this.state.modalRecordName} record</h1><br />
									<p className="line-break text-center">{this.state.modalRecordValue}</p><br />
							</>
						}

          </>
        <div className="footer text-center">
          <Button variant="secondary" onClick={this.handleCloseModal}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>



		<Modal className="reputation-modal blacklists-modal" show={this.state.showBlacklistModal} onHide={this.handleCloseModal}>
			<Modal.Body>
					<>
						<h1 className="text-center">Blacklists check</h1>
						<div className="txt text-center">

						<div className="alert status warning card bounce">
							<span>
								Read our article about being blacklisted
							</span>
							<a href="https://help.mailreach.co/en/article/my-domain-is-blacklisted-is-it-serious-and-what-to-do-1qlp1f7/"  target="_blank" target="_blank" className="btn pull-right">Read</a>
						</div>


						{this.state.blacklistsModal.length == 0 &&
							<>
								<img src="/images/taken.svg" />
								<p>We don't have blacklist data for this domain. If you use a freemail (Like @gmail.com or @outlook.com) we don't run blacklists check. If not, we should run a check on the next 24 hours 😉</p>
							</>
						}

						{this.state.blacklistsModal.length > 0 &&
							<table class="table">
								<thead>
									<tr>
										<th scope="col">List name</th>
										<th scope="col">Domain/IP</th>
										<th scope="col">Listed</th>
									</tr>
								</thead>
								<tbody>
								{this.state.blacklistsModal.map((blacklist, index) => {
									return (
										<tr>
											<td className="text-left">
												{blacklist.blacklist == 'fresh.spameatingmonkey.net' &&
													<Tooltip title="Don't panic, that's normal. This blacklist lists the 'fresh' domains that are only 5 days old or younger. It should go away once you pass the 5 days period." placement="bottom">
														<div>
															{blacklist.blacklist} &nbsp;
															<i class="fas fa-info-circle"></i>
														</div>
													</Tooltip>
												}
												{blacklist.blacklist != 'fresh.spameatingmonkey.net' &&
													<>
														{blacklist.blacklist}
													</>
												}
											</td>

											<td className="text-left">
												{blacklist.checked_value}
											</td>
											<td>
												{blacklist.listed &&
													<div className="red">
														<i class="far fa-times-circle"></i> Yes
													</div>
												}
												{!blacklist.listed &&
													<div className="green">
														<i class="far fa-check-circle"></i> No
													</div>
												}
											</td>
										</tr>
								)})}
								</tbody>
							</table>
						}
						</div>
					</>
				<div className="footer text-center">
					<Button variant="secondary" onClick={this.handleCloseModal}>
						Close
					</Button>
				</div>
			</Modal.Body>
		</Modal>
		</>
		)
	}
}



export default CheckupDomain;
